@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.custom-select span {
  left: 40px;
}
.custom-select + label + ul {
  min-height: 50px;
  max-height: 40vh;
}
.map-container {
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient {
  overflow: hidden;

}
div[data-floating-ui-portal]:has(div#agent-modal)>div {
  overflow: hidden !important;
}
div[data-floating-ui-portal]:has(div#agent-modal)>div>div {
  position: relative;
  height: 100%;
}
@media (max-width: 481px) {
  #agent-modal {
    min-height: 100%;
    max-height: none;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }
}

.gradient-before:before {
  content: '';
  position: absolute;
  bottom: 0;
  background: linear-gradient(to right, transparent 0%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
  height: 100%;
  width: 30px;
  right: 25px;
  pointer-events: none;
}
.gradient-after:after {
  content: '';
  position: absolute;
  bottom: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0.8) 0%, transparent 100%);
  height: 100%;
  width: 30px;
  left: 25px;
  pointer-events: none;
}
.logo-gradient {
  background: linear-gradient(160deg, #36845B 0%, #3498DB 100%);
  animation: gradient-logo 5s ease infinite;
}
.logo-beat {
  animation: beat 0.75s infinite alternate;
  transform-origin: center;
}
.snackbar-success>button {
  color: #1e4620;
}
.snackbar-error>button {
  color: #57534e;
}

input.input-error:focus-visible {
  outline: 0;
  border-width: 2px;
} 
@keyframes beat {
  to {
    transform: scale(1.2);
  }
}

@keyframes gradient-logo {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}